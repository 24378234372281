<template>
  <b-card-code no-body>
    <b-card-body>
      <b-row>
        <b-col md="4">
          <b-form-group label="Descrição" label-for="descricao">
            <b-form-input
              :disabled="!this.acesso.ConfiguracoesWorkflowConsultar"
              id="descricao"
              placeholder="Descrição"
              v-model="pesquisar.descricao"
              v-on:keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>
        <b-col md="auto">
          <b-button
            :disabled="!this.acesso.ConfiguracoesWorkflowConsultar"
            id="botaoPesquisar"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="pesquisarGrupo()"
          >
            Pesquisar
          </b-button>
        </b-col>
        <b-col md="auto" align="right" v-show="this.acesso.ConfiguracoesWorkflowCriarNovo">
          <b-button
            :disabled="!this.acesso.ConfiguracoesWorkflowCriarNovo"
            id="botaoNovoGrupoAcessoRegistro1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="novoRegistro()"
          >
            Novo Cadastro
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-table
      striped
      class="responsive"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item @click="authEEdita(row.item.id)" v-show="acesso.ConfiguracoesWorkflowEditar">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>
          <b-dropdown-item @click="authEExclui(row.item.id)" v-show="acesso.ConfiguracoesWorkflowExcluir">
            <feather-icon icon="XIcon" size="16" />
            <span class="align-middle ml-50">Excluir</span>
          </b-dropdown-item>
          <b-dropdown-item @click="permissoes(row.item.id)" v-show="acesso.ConfiguracoesWorkflowEditar">
            <feather-icon icon="CpuIcon" size="16" />
            <span class="align-middle ml-50">Permissões</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- Modal Cadastro -->
    <b-modal
      id="modal-cadastro"
      ref="modal-cadastro"
      centered
      size="md"
      title="Cadastro"
      cancel-title="Fechar"
      ok-title="Salvar"
      cancel-variant="outline-secondary"
      @ok="salvar"
      no-close-on-backdrop
    >
      <validation-observer ref="formGrupo">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Nome" label-for="nome">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-input
                    id="inputNomeDoGrupo"
                    type="text"
                    placeholder="Nome do Grupo"
                    v-model="dados.nome"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal id="modal-workflow-modal" ref="modal-workflow-modal" centered size="xl" hide-footer>
      <workflowModal v-if="grupoPermissao" :general-data="grupoPermissao" />
    </b-modal>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import workflowModal from './workflowModal.vue'

  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      workflowModal,
      AutenticaSenha,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: { descricao: '', perPage: 10, currentPage: 1 },
        isBusy: true,
        required,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'nome', label: 'Nome', sortable: true },
        ],
        items: [],
        dados: {},
        acesso: {},
        grupoPermissao: {},
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesWorkflowConsultar = true
        this.acesso.ConfiguracoesWorkflowCriarNovo = true
        this.acesso.ConfiguracoesWorkflowEditar = true
        this.acesso.ConfiguracoesWorkflowExcluir = true
      } else {
        this.acesso.ConfiguracoesWorkflowConsultar = this.userData.GrupoAcesso.ConfiguracoesWorkflowConsultar
        this.acesso.ConfiguracoesWorkflowCriarNovo = this.userData.GrupoAcesso.ConfiguracoesWorkflowCriarNovo
        this.acesso.ConfiguracoesWorkflowEditar = this.userData.GrupoAcesso.ConfiguracoesWorkflowEditar
        this.acesso.ConfiguracoesWorkflowExcluir = this.userData.GrupoAcesso.ConfiguracoesWorkflowExcluir
      }
      this.carregarGrid()
    },
    methods: {
      pesquisarGrupo() {
        this.pesquisar = { ...this.pesquisar, currentPage: 1 }
        this.carregarGrid()
      },
      authEEdita(item) {
        this.items.item = item
        this.items.mode = 'authEEdita'
        this.$refs['modal-auth-senha'].show()
      },
      authEExclui(item) {
        this.items.item = item
        this.items.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEEdita') {
            this.editar(this.items.item)
          } else if (this.items.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      carregarGrid() {
        const institutoId = this.userData.Instituto.id

        this.isBusy = true
        useJwt
          .pesquisar(`grupo-acesso/GetpesquisarGrupoInstID/${institutoId}`, this.pesquisar)
          .then((response) => {
            this.isBusy = !this.isBusy
            this.items = response.data

            this.items = this.items.sort((grupoA, grupoB) => grupoA.nome.localeCompare(grupoB.nome))

            this.totalRows = this.items.length
          })
          .catch((error) => {
            console.error(error)
          })
      },
      novoRegistro() {
        this.dados = {}
        this.$refs['modal-cadastro'].show()
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o cadastro selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`grupo-acesso/excluirGrupo`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: Registro possui relacionamentos!',
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      editar(item) {
        useJwt
          .get(`grupo-acesso/editarGrupo/${item}`)
          .then((response) => {
            this.dados = response.data
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formGrupo.validate().then((success) => {
          if (success) {
            if (this.dados.id == undefined) {
              const aux = {
                ...this.dados,
                institutoId: this.userData.institutoSelecionado,
              }

              useJwt
                .post(`grupo-acesso/cadastrarGrupo`, aux)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Salvo com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((response) => {
                    this.$refs['modal-cadastro'].hide()
                    this.carregarGrid()
                  })
                })
                .catch((error) => {
                  console.error(error)
                })
            } else {
              const { id, ...aux } = this.dados

              useJwt
                .update(`grupo-acesso/alterarGrupo`, this.dados.id, aux)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Registro alterado com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((response) => {
                    this.$refs['modal-cadastro'].hide()
                    this.carregarGrid()
                  })
                })
                .catch((error) => {
                  console.error(error)
                })
            }
          }
        })
      },
      permissoes(item) {
        useJwt
          .get(`grupo-acesso/editarGrupoPermissoes/${item}`)
          .then((response) => {
            this.grupoPermissao = response.data
            this.$refs['modal-workflow-modal'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
