<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col lg="3">
        <b-card-header
          header="Importar / Exportar"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.ImportarExportar" @change="marcarDesmarcarHandler">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ImportarExportarCadastro">Cadastro</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ImportarExportarMargemConsignavel">Proposta</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BButton,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BCardText,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: this.generalData,
      }
    },
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CImportarExportar() {
        const value = this.optionsLocal.ImportarExportar

        ;(this.optionsLocal.ImportarExportarMargemConsignavel = value),
          (this.optionsLocal.ImportarExportarCadastro = value),
          this.updateGeneralData()
      },
      marcarDesmarcarHandler() {
        this.CImportarExportar()
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
