<template>
  <div>
    <b-tabs>
      <b-card-text class="mb-1" style="color: red">
        Por padrão, um grupo vem com todas as permissões. Logo, retire as permissões não desejadas para o grupo de acesso:
        <strong>{{ options.nome }}</strong>
      </b-card-text>

      <b-tab active>
        <template #title>
          <feather-icon icon="UserPlusIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Cadastro</span>
        </template>
        <workflowModalCadastro v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ShoppingBagIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Simulador de proposta</span>
        </template>
        <workflowModalSimulador v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="SlidersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contrato</span>
        </template>
        <workflowModalContrato v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Financeiro</span>
        </template>
        <workflowModalFinanceiro v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="AlignJustifyIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Renegociação</span>
        </template>
        <workflowModalRenegociacao v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="BarChartIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Relatório</span>
        </template>
        <workflowModalRelatorio v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="MonitorIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Dashboards</span>
        </template>
        <workflowModalDashboards v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="HardDriveIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Importar/Exportar</span>
        </template>
        <workflowModalImportarExportar v-if="options" :generalData.sync="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Configurações</span>
        </template>
        <workflowModalConfiguracoes v-if="options" :generalData.sync="options" />
      </b-tab>
    </b-tabs>

    <b-row>
      <b-col cols="2">
        <b-button variant="primary" class="mb-1" @click.prevent="salvarModal"> Salvar </b-button>
      </b-col>
    </b-row>

    <!-- modal aceitar-->
    <b-modal
      id="modal-aceitar"
      ref="modal-aceitar"
      centered
      ok-title="Sim!"
      cancel-title="Não!"
      ok-variant="primary"
      cancel-variant="danger"
      no-close-on-backdrop
      @ok="salvar"
      @cancel="closeModal"
    >
      <div class="text-center">
        <b-icon icon="exclamation-circle" variant="warning" font-scale="5"></b-icon>
        <h4 class="font-weight-bold mt-3 h1">Atenção!</h4>
        <p class="my-2 h3">Deseja realmente salvar as alterações realizadas?</p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex justify-content-center w-100">
          <b-button @click="ok" variant="primary" class="mx-1 h2 w-20">Sim!</b-button>
          <b-button @click="cancel" variant="danger" class="mx-1 h2 w-20">Não!</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BTab, BTabs, BCardText } from 'bootstrap-vue'
  import workflowModalCadastro from './workflowModalCadastro.vue'
  import workflowModalSimulador from './workflowModalSimulador.vue'
  import workflowModalContrato from './workflowModalContrato.vue'
  import workflowModalRelatorio from './workflowModalRelatorio.vue'
  import workflowModalImportarExportar from './workflowModalImportarExportar.vue'
  import workflowModalConfiguracoes from './WorkflowModalConfiguracoes/workflowModalConfiguracoes.vue'
  import workflowModalFinanceiro from './workflowModalFinanceiro.vue'
  import workflowModalDashboards from './workflowModalDashboards.vue'
  import workflowModalRenegociacao from './workflowModalRenegociacao.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BTab,
      BTabs,
      BCardText,
      workflowModalCadastro,
      workflowModalSimulador,
      workflowModalContrato,
      workflowModalRelatorio,
      workflowModalImportarExportar,
      workflowModalConfiguracoes,
      workflowModalFinanceiro,
      workflowModalDashboards,
      workflowModalRenegociacao,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        options: JSON.parse(JSON.stringify(this.generalData)),
      }
    },
    methods: {
      salvarModal() {
        this.$refs['modal-aceitar'].show()
      },
      closeModal() {
        this.$refs['modal-aceitar'].hide()
      },
      salvar() {
        const parametros = { ...this.options }

        useJwt
          .update(`grupo-acesso/putGrupoAcesso`, this.options.id, parametros)
          .then((response) => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
    watch: {
      options(newValue, oldValue) {
        this.options = newValue
      },
    },
  }
</script>

<style scoped>
  .font-weight-bold {
    font-weight: bold;
  }
</style>
