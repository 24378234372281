<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col lg="3">
        <b-card-header
          header="Pagamento"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamento" @change="marcarDesmarcarHandler('Pagamento')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoImportarExportar">Importar/Exportar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoGerarRemessa">Gerar Remessa</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoImportarRetorno">Importar Retorno</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Remessa Boletos"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.FinanceiroRemessaBoleto"
                @change="marcarDesmarcarHandler('RemessaBoletos')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoImportarExportar"
                >Importar/Exportar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoGerarRemessa">Gerar Remessa</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoGerarRetorno">Gerar Retorno</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Lançamentos"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamento" @change="marcarDesmarcarHandler('Lancamentos')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoGerar">Gerar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoRecalcularParcelas"
                >Recálculo de Parcelas - Recalcular</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoEnviarLancamento"
                >Recálculo de Parcelas - Enviar para lançamentos</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoNaoDescontadoIntegracao"
                >Não descontados - Integração</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoNaoDescontadoBaixar"
                >Não descontados - Baixar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoNaoDescontadoExtrato"
                >Não descontados - Extrato de parcelas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoDescontadoExportar"
                >Descontadas - Exportar</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Arrecadação"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacao" @change="marcarDesmarcarHandler('Arrecadacao')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoGerar">Gerar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoGerarGuia">Gerar Guia</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoGerarBoleto">Gerar Boleto</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoConfirmarPagamento"
                >Confirmar Pagamento</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Ripple from 'vue-ripple-directive'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: this.generalData,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CFinanceiroPagamento() {
        const value = this.optionsLocal.FinanceiroPagamento

        ;(this.optionsLocal.FinanceiroPagamentoEditar = value),
          (this.optionsLocal.FinanceiroPagamentoImportarExportar = value),
          (this.optionsLocal.FinanceiroPagamentoGerarRemessa = value),
          (this.optionsLocal.FinanceiroPagamentoImportarRetorno = value),
          this.updateGeneralData()
      },
      CFinanceiroRemessaBoleto() {
        const value = this.optionsLocal.FinanceiroRemessaBoleto

        ;(this.optionsLocal.FinanceiroRemessaBoletoEditar = value),
          (this.optionsLocal.FinanceiroRemessaBoletoImportarExportar = value),
          (this.optionsLocal.FinanceiroRemessaBoletoGerarRemessa = value),
          (this.optionsLocal.FinanceiroRemessaBoletoGerarRetorno = value),
          this.updateGeneralData()
      },
      CFinanceiroLancamento() {
        const value = this.optionsLocal.FinanceiroLancamento

        ;(this.optionsLocal.FinanceiroLancamentoEditar = value),
          (this.optionsLocal.FinanceiroLancamentoGerar = value),
          (this.optionsLocal.FinanceiroLancamentoRecalcularParcelas = value),
          (this.optionsLocal.FinanceiroLancamentoEnviarLancamento = value),
          (this.optionsLocal.FinanceiroLancamentoNaoDescontadoIntegracao = value),
          (this.optionsLocal.FinanceiroLancamentoNaoDescontadoBaixar = value),
          (this.optionsLocal.FinanceiroLancamentoNaoDescontadoExtrato = value),
          (this.optionsLocal.FinanceiroLancamentoDescontadoExportar = value),
          this.updateGeneralData()
      },
      CFinanceiroArrecadacao() {
        const value = this.optionsLocal.FinanceiroArrecadacao

        ;(this.optionsLocal.FinanceiroArrecadacaoEditar = value),
          (this.optionsLocal.FinanceiroArrecadacaoGerar = value),
          (this.optionsLocal.FinanceiroArrecadacaoGerarGuia = value),
          (this.optionsLocal.FinanceiroArrecadacaoGerarBoleto = value),
          (this.optionsLocal.FinanceiroArrecadacaoConfirmarPagamento = value),
          this.updateGeneralData()
      },
      marcarDesmarcarHandler(tipo) {
        switch (tipo) {
          case 'Pagamento':
            this.CFinanceiroPagamento()
            break
          case 'RemessaBoletos':
            this.CFinanceiroRemessaBoleto()
            break
          case 'Lancamentos':
            this.CFinanceiroLancamento()
            break
          case 'Arrecadacao':
            this.CFinanceiroArrecadacao()
            break
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
