<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col lg="3">
        <b-card-header
          header="Cadastro"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.RelatorioCadastro" @change="marcarDesmarcarHandler('RelatorioCadastro')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioCadastro" v-model="optionsLocal.RelatorioCadastroTomadores"
                >Tomadores</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioCadastro" v-model="optionsLocal.RelatorioCadastroUsuarios"
                >Usuários</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Simulador"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.RelatorioSimulador" @change="marcarDesmarcarHandler('RelatorioSimulador')"
                >Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioSimulador" v-model="optionsLocal.RelatorioSimuladorProposta"
                >Proposta</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioSimulador" v-model="optionsLocal.RelatorioSimuladorExterno"
                >Externo</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Contrato"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.RelatorioContrato" @change="marcarDesmarcarHandler('RelatorioContrato')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioContrato" v-model="optionsLocal.RelatorioContratoContratos"
                >Contratos</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioContrato" v-model="optionsLocal.RelatorioContratoSaldoDevedor"
                >Saldo Devedor</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioContrato"
                v-model="optionsLocal.RelatorioContratoSuspensaoJudicial"
                >Suspensão Judicial</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Financeiro"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.RelatorioFinanceiro"
                @change="marcarDesmarcarHandler('RelatorioFinanceiro')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroLancamento"
                >Lançamentos</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroArrecadacao"
                >Arrecadação</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroTaxasParcelas"
                >Taxas e parcelas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioFinanceiro" v-model="optionsLocal.RelatorioFinanceiroIOF"
                >IOF</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroCapitalAplicado"
                >Capital Aplicado x Rentabilidade</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioFinanceiro" v-model="optionsLocal.RelatorioFinanceiroContabil"
                >Contábil</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroParcelasAntecipadas"
                >Parcelas Antecipadas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioFinanceiro" v-model="optionsLocal.RelatorioFinanceiroDAIR"
                >DAIR</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Renegociação"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.RelatorioRenegociacao"
                @change="marcarDesmarcarHandler('RelatorioRenegociacao')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioRenegociacao"
                v-model="optionsLocal.RelatorioRenegociacaoDividas"
                >Dívidas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioRenegociacao"
                v-model="optionsLocal.RelatorioRenegociacaoAntecipacao"
                >Antecipação</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BButton,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BCardText,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: this.generalData,
      }
    },
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CCadastro() {
        const value = this.optionsLocal.RelatorioCadastro

        ;(this.optionsLocal.RelatorioCadastroTomadores = value),
          (this.optionsLocal.RelatorioCadastroUsuarios = value),
          this.updateGeneralData()
      },
      CSimulador() {
        const value = this.optionsLocal.RelatorioSimulador

        ;(this.optionsLocal.RelatorioSimuladorProposta = value),
          (this.optionsLocal.RelatorioSimuladorExterno = value),
          this.updateGeneralData()
      },
      CContrato() {
        const value = this.optionsLocal.RelatorioContrato

        ;(this.optionsLocal.RelatorioContratoContratos = value),
          (this.optionsLocal.RelatorioContratoSaldoDevedor = value),
          (this.optionsLocal.RelatorioContratoSuspensaoJudicial = value),
          this.updateGeneralData()
      },
      CFinanceiro() {
        const value = this.optionsLocal.RelatorioFinanceiro

        ;(this.optionsLocal.RelatorioFinanceiroExportar = value),
          (this.optionsLocal.RelatorioFinanceiroLancamento = value),
          (this.optionsLocal.RelatorioFinanceiroArrecadacao = value),
          (this.optionsLocal.RelatorioFinanceiroTaxasParcelas = value),
          (this.optionsLocal.RelatorioFinanceiroIOF = value),
          (this.optionsLocal.RelatorioFinanceiroCapitalAplicado = value),
          (this.optionsLocal.RelatorioFinanceiroContabil = value),
          (this.optionsLocal.RelatorioFinanceiroParcelasAntecipadas = value),
          (this.optionsLocal.RelatorioFinanceiroDAIR = value),
          this.updateGeneralData()
      },

      CRenegociacao() {
        const value = this.optionsLocal.RelatorioRenegociacao

        ;(this.optionsLocal.RelatorioRenegociacaoDividas = value),
          (this.optionsLocal.RelatorioRenegociacaoAntecipacao = value),
          this.updateGeneralData()
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'RelatorioCadastro') {
          this.CCadastro()
        }
        if (tipo == 'RelatorioSimulador') {
          this.CSimulador()
        }
        if (tipo == 'RelatorioContrato') {
          this.CContrato()
        }
        if (tipo == 'RelatorioFinanceiro') {
          this.CFinanceiro()
        }
        if (tipo == 'RelatorioRenegociacao') {
          this.CRenegociacao()
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
