<template>
  <div>
    <b-row>
      <b-col lg="2">
        <b-card-header
          header="Grupo de Acesso"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.CadastroUsuario" @change="marcarDesmarcarHandler('Usuario')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.CadastroUsuario" v-model="optionsLocal.CadastroUsuarioConsulta"
                >Consultar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.CadastroUsuario" v-model="optionsLocal.CadastroUsuarioEditar"
                >Editar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.CadastroUsuario" v-model="optionsLocal.CadastroUsuarioCriarNovo"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.CadastroUsuario" v-model="optionsLocal.CadastroUsuarioExcluir"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      optionsLocal: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CCadastroUsuario() {
        const value = this.optionsLocal.CadastroUsuario

        this.optionsLocal.CadastroUsuarioConsulta = value
        this.optionsLocal.CadastroUsuarioCriarNovo = value
        this.optionsLocal.CadastroUsuarioEditar = value
        this.optionsLocal.CadastroUsuarioExcluir = value

        this.updateGeneralData()
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'Usuario') {
          this.CCadastroUsuario()
        }
      },
    },
  }
</script>
