<template>
  <div>
    <b-row>
      <b-col lg="2">
        <b-card-header
          header="Modelos"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesModelos" @change="marcarDesmarcarHandler('Modelos')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.ConfiguracoesModelos"
                v-model="optionsLocal.ConfiguracoesModelosConsultar"
                >Consultar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.ConfiguracoesModelos"
                v-model="optionsLocal.ConfiguracoesModelosCriarNovo"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.ConfiguracoesModelos" v-model="optionsLocal.ConfiguracoesModelosExcluir"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      optionsLocal: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CConfiguracoesModelos() {
        const value = this.optionsLocal.ConfiguracoesModelos
        this.optionsLocal.ConfiguracoesModelosEditar = value
        this.optionsLocal.ConfiguracoesModelosConsultar = value
        this.optionsLocal.ConfiguracoesModelosCriarNovo = value
        this.optionsLocal.ConfiguracoesModelosExcluir = value
        this.updateGeneralData()
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'Modelos') {
          this.CConfiguracoesModelos()
        }
      },
    },
  }
</script>
