<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col lg="3">
        <b-card-header
          header="Tomador"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroTomador" @change="marcarDesmarcarHandler('CadastroTomador')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.CadastroTomador"
                v-model="optionsLocal.CadastroTomadorConsulta"
                @change="marcarDesmarcarHandler('CadastroTomadorConsulta')"
                >Consultar</b-form-checkbox
              >

              <b-form-group> </b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.CadastroTomador || !optionsLocal.CadastroTomadorConsulta"
                v-model="optionsLocal.CadastroTomadorEditar"
                >Editar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroTomadorCriarNovo"
                :disabled="!optionsLocal.CadastroTomador || !optionsLocal.CadastroTomadorConsulta"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroTomadorExcluir"
                :disabled="!optionsLocal.CadastroTomador || !optionsLocal.CadastroTomadorConsulta"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Órgão"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.CadastroOrgao" @change="marcarDesmarcarHandler('CadastroOrgao')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoConsulta"
                :disabled="!optionsLocal.CadastroOrgao"
                @change="marcarDesmarcarHandler('CadastroOrgaoConsulta')"
                >Consultar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoEditar"
                :disabled="!optionsLocal.CadastroOrgao || !optionsLocal.CadastroOrgaoConsulta"
                >Editar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoCriarNovo"
                :disabled="!optionsLocal.CadastroOrgao || !optionsLocal.CadastroOrgaoConsulta"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoExcluir"
                :disabled="!optionsLocal.CadastroOrgao || !optionsLocal.CadastroOrgaoConsulta"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Grupo Folha"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                switches
                v-model="optionsLocal.CadastroGrupoFolha"
                @change="marcarDesmarcarHandler('CadastroGrupoFolha')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaConsulta"
                :disabled="!optionsLocal.CadastroGrupoFolha"
                @change="marcarDesmarcarHandler('CadastroGrupoFolhaConsulta')"
                >Consultar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaEditar"
                :disabled="!optionsLocal.CadastroGrupoFolha || !optionsLocal.CadastroGrupoFolhaConsulta"
                >Editar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaCriarNovo"
                :disabled="!optionsLocal.CadastroGrupoFolha || !optionsLocal.CadastroGrupoFolhaConsulta"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaExcluir"
                :disabled="!optionsLocal.CadastroGrupoFolha || !optionsLocal.CadastroGrupoFolhaConsulta"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BButton,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BCardText,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: this.generalData,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    mounted() {},
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CCadastroTomador() {
        const value = this.optionsLocal.CadastroTomador

        ;(this.optionsLocal.CadastroTomadorConsulta = value),
          (this.optionsLocal.CadastroTomadorEditar = value),
          (this.optionsLocal.CadastroTomadorCriarNovo = value),
          (this.optionsLocal.CadastroTomadorExcluir = value),
          this.updateGeneralData()
      },
      CCadastroOrgao() {
        const value = this.optionsLocal.CadastroOrgao

        ;(this.optionsLocal.CadastroOrgaoConsulta = value),
          (this.optionsLocal.CadastroOrgaoEditar = value),
          (this.optionsLocal.CadastroOrgaoCriarNovo = value),
          (this.optionsLocal.CadastroOrgaoExcluir = value),
          this.updateGeneralData()
      },
      CCadastroGrupoFolha() {
        const value = this.optionsLocal.CadastroGrupoFolha

        ;(this.optionsLocal.CadastroGrupoFolhaConsulta = value),
          (this.optionsLocal.CadastroGrupoFolhaEditar = value),
          (this.optionsLocal.CadastroGrupoFolhaCriarNovo = value),
          (this.optionsLocal.CadastroGrupoFolhaExcluir = value),
          this.updateGeneralData()
      },

      marcarDesmarcarHandler(tipo) {
        this.CCadastroTomador()
        if (tipo == 'CadastroTomadorConsulta') {
          const value = this.optionsLocal.CadastroTomadorConsulta

          ;(this.optionsLocal.CadastroTomadorEditar = value),
            (this.optionsLocal.CadastroTomadorCriarNovo = value),
            (this.optionsLocal.CadastroTomadorExcluir = value),
            this.updateGeneralData()
        }
        if (tipo == 'CadastroOrgao') {
          this.CCadastroOrgao()
        }
        if (tipo == 'CadastroOrgaoConsulta') {
          const value = this.optionsLocal.CadastroOrgaoConsulta
          ;(this.optionsLocal.CadastroOrgaoEditar = value),
            (this.optionsLocal.CadastroOrgaoCriarNovo = value),
            (this.optionsLocal.CadastroOrgaoExcluir = value),
            this.updateGeneralData()
        }
        if (tipo == 'CadastroGrupoFolha') {
          this.CCadastroGrupoFolha()
        }
        if (tipo == 'CadastroGrupoFolhaConsulta') {
          const value = this.optionsLocal.CadastroGrupoFolhaConsulta

          ;(this.optionsLocal.CadastroGrupoFolhaEditar = value),
            (this.optionsLocal.CadastroGrupoFolhaCriarNovo = value),
            (this.optionsLocal.CadastroGrupoFolhaExcluir = value),
            this.updateGeneralData()
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
